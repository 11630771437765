export const de = {
    shop: {
        'public.shop.pointsCurrency': 'Punkte',
    },
    referral: {
        'public.referral.refereeMessage': '',
        'public.referral.shareMessage': '',
    },
    promotion: {
        'public.promotion.title': '',
        'public.promotion.description': '',
    },
    'earn-rule': {
        'public.earnRule.title': 'Verdienen Sie {{pointsCurrency}}',
        'public.earnRule.subtitle': 'So verdienen Sie {{pointsCurrency}}',
        'public.earnRule.type.CREATE_ACCOUNT.title': 'Erstellen Sie ein Konto und erhalten Sie {{points}} {{pointsCurrency}}',
        'public.earnRule.type.PLACE_ORDER.title': 'Für jeden ausgegebenen Betrag von 5€ erhalten Sie 50 {{pointsCurrency}}',
        'public.earnRule.type.CELEBRATE_BIRTHDAY.title': 'An Ihrem Geburtstag erhalten Sie {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_INSTAGRAM.title':
            'Folgen Sie uns auf Instagram {{handle}} und erhalten Sie {{points}} {{pointsCurrency}}',
        'public.earnRule.type.LIKE_ON_FACEBOOK.title':
            'Gefällt mir auf Facebook {{handle}} und verdiene {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SHARE_ON_FACEBOOK.title':
            'Teile unseren Shop auf Facebook {{handle}} und verdiene {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TWITTER.title': 'Folge uns auf X {{handle}} und verdiene {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TIKTOK.title':
            'Folge uns auf TikTok {{handle}} und verdiene {{points}} {{pointsCurrency}}',
    },
    'spend-rule': {
        'public.spendRule.type.FREE_SHIPPING.title': 'Erhalten Sie kostenlose Lieferung für Ihre nächste Bestellung',
        'public.spendRule.type.FREE_SHIPPING.fulfillment': 'Kostenlose Lieferung',
        'public.spendRule.type.FREE_SHIPPING.instructions': 'Verwenden Sie diesen Rabattcode für Ihre nächste Bestellung!',
        'public.spendRule.type.PRODUCT_DISCOUNT.title':
            'Lösen Sie {{points}} {{pointsCurrency}} ein und erhalten Sie {{discount}} Rabatt',
        'public.spendRule.type.PRODUCT_DISCOUNT.fulfillment': '{{discount}} Rabatt-Coupon',
        'public.spendRule.type.PRODUCT_DISCOUNT.instructions': 'Verwenden Sie diesen Rabattcode für Ihre nächste Bestellung!',
        'public.spendRule.type.ORDER_DISCOUNT.title':
            'Lösen Sie {{points}} {{pointsCurrency}} ein und erhalten Sie {{discount}} Rabatt auf Ihre Bestellung',
        'public.spendRule.type.ORDER_DISCOUNT.fulfillment': '{{discount}} Rabatt-Coupon',
        'public.spendRule.type.ORDER_DISCOUNT.instructions': 'Verwenden Sie diesen Rabattcode für Ihre nächste Bestellung!',
        'public.spendRule.type.POS_DISCOUNT.title':
            'Löse {{points}} {{pointsCurrency}} ein und erhalte {{discount}} Rabatt auf deine Bestellung',
        'public.spendRule.type.POS_DISCOUNT.fulfillment': '{{discount}} Rabatt-Coupon',
        'public.spendRule.type.POS_DISCOUNT.instructions': 'Rabatt am Verkaufspunkt, im Geschäft einlösbar!',
        'public.spendRule.type.FREE_PRODUCT.title': 'Löse {{points}} {{pointsCurrency}} ein und erhalte ein kostenloses Produkt',
        'public.spendRule.type.FREE_PRODUCT.fulfillment': 'Kostenloses Produkt',
        'public.spendRule.type.FREE_PRODUCT.instructions': 'Verwende diesen Rabattcode bei deiner nächsten Bestellung!',
    },
    branding: {
        'public.widget.authenticated.header.title': 'Treueprogramm & Belohnungen',
        'public.widget.unauthenticated.header.title': 'Hallo {{customerName}}!',
        'public.widget.becomeAMember.title': 'Werden Sie Mitglied',
        'public.widget.becomeAMember.subtitle':
            'Treten Sie unserem Treueprogramm bei und beginnen Sie noch heute, Belohnungen zu verdienen.',
        'public.widget.becomeAMember.joinProgram': 'Programm beitreten',
        'public.widget.becomeAMember.alreadyAMember': 'Bereits Mitglied ?',
        'public.widget.becomeAMember.login': 'Anmelden',
        'public.widget.balanceCard.points': '{{pointsCurrency}}',
        'public.widget.balanceCard.referrals': 'Empfehlungen',
        'public.widget.balanceCard.tier': 'Stufe',
        'public.widget.spendAndEarnCard.title': 'Belohnungen',
        'public.widget.spendAndEarnCard.subtitle':
            'Sammeln Sie {{pointsCurrency}}, erschließen Sie Belohnungen – Ihr Einkauf wird noch aufregender!',
        'public.widget.spendAndEarnCard.earn.title': 'Möglichkeiten, {{pointsCurrency}} zu verdienen',
        'public.widget.spendAndEarnCard.spend.title': 'Möglichkeiten, {{pointsCurrency}} auszugeben',
        'public.widget.spendAndEarnCard.vouchers.title': 'Deine Gutscheine anzeigen',
        'public.widget.referralPage.title': 'Empfehlen Sie einen Freund',
        'public.widget.activityCard.title': 'Aktivität',
        'public.widget.referralCard.title': 'Laden Sie Ihre Freunde ein und verdienen Sie Belohnungen',
        'public.widget.tiersPage.title': 'Stufen',
        'public.widget.tiersPage.subtitle': 'Erschließen Sie mehr Belohnungen, wenn Sie in höhere Stufen aufsteigen',
        'public.widget.vouchersPage.title': 'Ihre Gutscheine',
        'public.widget.vouchersPage.subtitle': 'Überprüfen Sie Ihre verfügbaren Gutscheine',
        'public.widget.rewardsPage.title': 'Belohnungen',
        'public.widget.rewardsPage.subtitle': 'So können Sie Ihre hart verdienten {{pointsCurrency}} einlösen',
        'public.widget.earnPointsPage.title': '{{pointsCurrency}} verdienen',
        'public.widget.earnPointsPage.subtitle': 'So verdienen Sie {{pointsCurrency}}',
        'public.widget.activityPage.title': 'Aktivität',
        'public.widget.activityPage.subtitle': 'Überprüfen Sie Ihre letzte Aktivität',
        'public.widget.activityPage.noActivity.title': 'Noch keine Aktivität',
        'public.widget.rewardsCard.title': 'Belohnungen',
        'public.widget.rewardsCard.subtitle':
            'Sammeln Sie {{pointsCurrency}}, erschließen Sie Belohnungen – Ihr Einkauf wird noch aufregender!',
        // Full page
        'public.fullPage.unauthenticatedHeroTitle':
            'Treten Sie dem Treueprogramm bei und erhalten Sie Belohnungen beim Einkaufen. Sie erhalten {{points}} {{pointsCurrency}} für die Anmeldung',
        'public.fullPage.authenticatedHeroTitle': 'Sie haben {{points}} {{pointsCurrency}}',
        'public.fullPage.hero.joinProgram': 'Programm beitreten',
        'public.fullPage.hero.login': 'Anmelden',
        'public.fullPage.navigation.earnPoints': '{{pointsCurrency}} verdienen',
        'public.fullPage.navigation.rewards': 'Belohnungen',
        'public.fullPage.navigation.tiers': 'Stufen',
        'public.fullPage.navigation.referralProgram': 'Empfehlen Sie einen Freund',
        'public.fullPage.navigation.activity': 'Deine Aktivität',
        'public.fullPage.navigation.vouchers': 'Ihre Gutscheine',
        'public.fullPage.referralCard.title': 'Empfehlen Sie einen Freund',
        // Referral Modal
        'public.referrals.title': 'Wir haben einen Rabatt für dich',
        'public.referrals.subtitle': 'Erhalten Sie 50% Rabatt auf Ihren ersten Einkauf!',
        'public.referrals.label': 'Geben Sie Ihre E-Mail-Adresse unten ein und wir senden Ihnen den Rabattcode.',
        'public.referrals.button': 'Rabatt erhalten',
    },
};
