export const fr = {
    shop: {
        'public.shop.pointsCurrency': 'Points',
    },
    referral: {
        'public.referral.refereeMessage': '',
        'public.referral.shareMessage': '',
    },
    promotion: {
        'public.promotion.title': '',
        'public.promotion.description': '',
    },
    'earn-rule': {
        'public.earnRule.title': 'Gagnez des {{pointsCurrency}}',
        'public.earnRule.subtitle': 'Voici comment gagner des {{pointsCurrency}}',
        'public.earnRule.type.CREATE_ACCOUNT.title': 'Créez un compte et gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.PLACE_ORDER.title': 'Pour chaque 5€ dépensés, gagnez 50 {{pointsCurrency}}',
        'public.earnRule.type.CELEBRATE_BIRTHDAY.title': 'Pour votre anniversaire, vous gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_INSTAGRAM.title':
            'Suivez-nous sur Instagram {{handle}} et gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.LIKE_ON_FACEBOOK.title':
            'Aimez notre page sur Facebook {{handle}} et gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SHARE_ON_FACEBOOK.title':
            'Partagez notre boutique sur Facebook {{handle}} et gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TWITTER.title': 'Suivez-nous sur X {{handle}} et gagnez {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TIKTOK.title':
            'Suivez-nous sur TikTok {{handle}} et gagnez {{points}} {{pointsCurrency}}',
    },
    'spend-rule': {
        'public.spendRule.type.FREE_SHIPPING.title': 'Obtenez la livraison gratuite sur votre prochaine commande',
        'public.spendRule.type.FREE_SHIPPING.fulfillment': 'Livraison gratuite',
        'public.spendRule.type.FREE_SHIPPING.instructions': 'Utilisez ce code promo sur votre prochaine commande !',
        'public.spendRule.type.PRODUCT_DISCOUNT.title':
            'Échangez {{points}} {{pointsCurrency}} et obtenez {{discount}} de réduction',
        'public.spendRule.type.PRODUCT_DISCOUNT.fulfillment': 'Coupon de réduction de {{discount}}',
        'public.spendRule.type.PRODUCT_DISCOUNT.instructions': 'Utilisez ce code promo sur votre prochaine commande !',
        'public.spendRule.type.ORDER_DISCOUNT.title':
            'Échangez {{points}} {{pointsCurrency}} et obtenez {{discount}} de réduction sur votre commande',
        'public.spendRule.type.ORDER_DISCOUNT.fulfillment': 'Coupon de réduction de {{discount}}',
        'public.spendRule.type.ORDER_DISCOUNT.instructions': 'Utilisez ce code promo sur votre prochaine commande !',
        'public.spendRule.type.POS_DISCOUNT.title':
            'Échangez {{points}} {{pointsCurrency}} et obtenez {{discount}} de réduction sur votre commande',
        'public.spendRule.type.POS_DISCOUNT.fulfillment': 'Coupon de réduction de {{discount}}',
        'public.spendRule.type.POS_DISCOUNT.instructions': 'Réduction en point de vente, à utiliser en magasin !',
        'public.spendRule.type.FREE_PRODUCT.title': 'Échangez {{points}} {{pointsCurrency}} et obtenez un produit gratuit',
        'public.spendRule.type.FREE_PRODUCT.fulfillment': 'Produit gratuit',
        'public.spendRule.type.FREE_PRODUCT.instructions': 'Utilisez ce code de réduction lors de votre prochaine commande !',
    },
    branding: {
        'public.widget.authenticated.header.title': 'Fidélité & Récompenses',
        'public.widget.unauthenticated.header.title': 'Salut {{customerName}} !',
        'public.widget.becomeAMember.title': 'Devenez membre',
        'public.widget.becomeAMember.subtitle':
            "Rejoignez notre programme de fidélité et commencez à gagner des récompenses dès aujourd'hui.",
        'public.widget.becomeAMember.joinProgram': 'Rejoindre le programme',
        'public.widget.becomeAMember.alreadyAMember': 'Déjà membre ?',
        'public.widget.becomeAMember.login': 'Connexion',
        'public.widget.balanceCard.points': '{{pointsCurrency}}',
        'public.widget.balanceCard.referrals': 'Parrainages',
        'public.widget.balanceCard.tier': 'Niveau',
        'public.widget.spendAndEarnCard.title': 'Récompenses',
        'public.widget.spendAndEarnCard.subtitle':
            'Collectez des {{pointsCurrency}}, débloquez des récompenses - vos achats viennent de devenir encore plus excitants !',
        'public.widget.spendAndEarnCard.earn.title': 'Moyens de gagner des {{pointsCurrency}}',
        'public.widget.spendAndEarnCard.spend.title': 'Moyens de dépenser des {{pointsCurrency}}',
        'public.widget.spendAndEarnCard.vouchers.title': 'Voir vos coupons',
        'public.widget.referralPage.title': 'Parrainez un ami',
        'public.widget.activityCard.title': 'Activité',
        'public.widget.referralCard.title': 'Invitez vos amis et gagnez des récompenses',
        'public.widget.tiersPage.title': 'Niveaux',
        'public.widget.tiersPage.subtitle': 'Débloquez plus de récompenses au fur et à mesure que vous montez en niveau',
        'public.widget.vouchersPage.title': 'Vos coupons',
        'public.widget.vouchersPage.subtitle': 'Découvrez vos coupons disponibles',
        'public.widget.rewardsPage.title': 'Récompenses',
        'public.widget.rewardsPage.subtitle': 'Voici comment dépenser vos {{pointsCurrency}} durement gagnés',
        'public.widget.earnPointsPage.title': 'Gagner des {{pointsCurrency}}',
        'public.widget.earnPointsPage.subtitle': 'Voici comment gagner des {{pointsCurrency}}',
        'public.widget.activityPage.title': 'Activité',
        'public.widget.activityPage.subtitle': 'Consultez votre activité récente',
        'public.widget.activityPage.noActivity.title': 'Aucune activité pour le moment',
        'public.widget.rewardsCard.title': 'Récompenses',
        'public.widget.rewardsCard.subtitle':
            'Collectez des {{pointsCurrency}}, débloquez des récompenses - vos achats viennent de devenir encore plus excitants !',
        // Full page
        'public.fullPage.unauthenticatedHeroTitle':
            'Rejoignez le programme de fidélité et obtenez des récompenses pendant vos achats. Vous obtiendrez {{points}} {{pointsCurrency}} en vous inscrivant',
        'public.fullPage.authenticatedHeroTitle': 'Vous avez {{points}} {{pointsCurrency}}',
        'public.fullPage.hero.joinProgram': 'Rejoindre le programme',
        'public.fullPage.hero.login': 'Connexion',
        'public.fullPage.navigation.earnPoints': 'Gagner des {{pointsCurrency}}',
        'public.fullPage.navigation.rewards': 'Récompenses',
        'public.fullPage.navigation.tiers': 'Niveaux',
        'public.fullPage.navigation.referralProgram': 'Parrainer un ami',
        'public.fullPage.navigation.activity': 'Votre activité',
        'public.fullPage.navigation.vouchers': 'Vos coupons',
        'public.fullPage.referralCard.title': 'Parrainer un ami',
        // Referral Modal
        'public.referrals.title': 'Nous avons une remise pour vous',
        'public.referrals.subtitle': 'Bénéficiez de 50% de réduction sur votre premier achat !',
        'public.referrals.label': 'Entrez votre e-mail ci-dessous et nous vous enverrons le code de réduction.',
        'public.referrals.button': 'Obtenir la réduction',
    },
};
